var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name: 'contratantes.view',
                id: _vm.id,
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            (_vm.contractor ? _vm.contractor.name + ' | ' : '') +
            _vm.$route.meta.title +
            (_vm.loading ? '' : ' (' + _vm.table_data.length + ')')
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","color":"warning","x-small":"","to":{
                name: 'contratantes.cpts_update',
                params: { id: _vm.id },
              }}},on),[_c('v-icon',[_vm._v(" mdi-file ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Actualizar')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","color":"success","x-small":"","to":{
                name: 'contratantes.cpts_store',
                params: { contractor_id: _vm.id },
              }}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Agregar')}})])],1),(_vm.table_data.length > 0)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.table_search),callback:function ($$v) {_vm.table_search=$$v},expression:"table_search"}})],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.table_headers,"search":_vm.table_search,"items":_vm.table_data,"loading":_vm.loading,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
              var item = ref.item;
return [_c('b',{domProps:{"textContent":_vm._s(item.key)}})]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.amount))+" ")]}},{key:"item.block_payment",fn:function(ref){
              var item = ref.item;
return [(!item.block_payment)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"warning","small":""}},[_vm._v(" mdi-check ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","x-small":"","color":"primary","to":{
                    name: 'contratantes.cpts_edit',
                    params: {
                      id: item.id,
                      contractor_id: _vm.id,
                    },
                  }}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Editar')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }